<template>
  <div class="bg-tt-yellow w-full h-full">
    <div class="area py-8 overflow-hidden flex justify-center">
      <div class="flex flex-wrap items-center justify-center">
        <!-- <h1 class="text-4xl">Triptalk Audio Guide</h1> -->
        <img
          class="max-w-sm"
          src="../assets/mockup-front.png"
          alt="Front View of the Triptalk App"
        />
        <div class="flex flex-col justify-center ">
          <div class=" text-center p-8 rounded-lg space-y-4">
            <a
              href="https://apps.apple.com/us/app/triptalk-audio-guide/id1552018525?itsct=apps_box&amp;itscg=30200"
              style="display: inline-block; border-radius: 13px; width: 250px; height: 83px;"
              ><img
                src="../assets/app_store.svg"
                alt="Download on the App Store"
                style="border-radius: 13px; width: 250px; height: 83px;"
            /></a>
          </div>
          <div class="text-center p-4 lg:p-8 rounded-lg space-y-4">
            <div class="mx-auto max-w-xs">
              <a
                href="https://play.google.com/store/apps/details?id=de.gormlabenz.triptalk&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                style="display: inline-block; border-radius: 13px; width: 250px; height: 83px;"
                ><img
                  class="object-fill"
                  alt="Get it on Google Play"
                  src="../assets/google_play.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.detectAndServe();
  },
  methods: {
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "unknown";
    },
    detectAndServe() {
      let os = this.getMobileOperatingSystem();
      console.log("os", os);
      if (os == "Android") {
        window.location.href =
          "https://play.google.com/store/apps/details?id=de.gormlabenz.triptalk";
      } else if (os == "iOS") {
        window.location.href =
          "https://apps.apple.com/us/app/triptalk-audio-guide/id1552018525?itsct=apps_box&amp;itscg=30200";
      } else if (os == "Windows Phone") {
        return;
        //window.location.href = "http://www.WindowsPhoneexample.com";
      } else {
        return;
        //window.location.href = "http://www.NowherLandexample.com";
      }
    }
  }
};
</script>

<style></style>
