<template>
  <header id="nav" class="w-full fixed z-50 bg-white">
    <div class="py-2 shadow-md">
      <div class="area flex justify-between items-center">
        <router-link class="flex items-center space-x-4" to="/"
          ><img
            class="object-contain w-8 "
            alt="Triptalk logo"
            src="../assets/logo-yellow.png"
          />
          <p class="font-bold text-lg">Triptalk</p></router-link
        >
        <div class="space-x-4 hidden md:flex ">
          <TtButton
            link="/about"
            icon="information-circle-outline"
            color="bg-white"
            >About</TtButton
          >
          <TtButton
            link="/contact"
            icon="people-circle-outline"
            color="bg-white"
            >Contact</TtButton
          >
          <TtButton link="/app" icon="download">Get the App!</TtButton>
        </div>

        <ion-icon
          @click="menu = !menu"
          class="cursor-pointer mt-1  text-tt-yellow text-4xl  md:hidden"
          name="menu-outline"
        ></ion-icon>
      </div>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div v-if="menu" class="area relative md:hidden">
        <div
          class="absolute top-0 right-6 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 inline-block p-1 space-y-3"
        >
          <div
            class="inline-flex flex-col"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <TtButton
              link="/about"
              icon="information-circle-outline"
              color="bg-white"
              >About</TtButton
            >
            <TtButton
              @clicked="menu = false"
              link="/contact"
              icon="people-circle-outline"
              color="bg-white"
              >Contact</TtButton
            >
            <TtButton @clicked="menu = false" link="/app" icon="download"
              >Get the App!</TtButton
            >
          </div>
        </div>
      </div>
    </transition>
  </header>
</template>

<script>
export default {
  data() {
    return {
      menu: false
    };
  }
};
</script>

<style></style>
