import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "@/assets/style.css";
//import "mapbox-gl/dist/mapbox-gl.css";
import router from "./router";

Vue.config.productionTip = false;

import VWave from "v-wave";
Vue.use(VWave);

Vue.config.ignoredElements = [/^ion-/];

import TtButton from "./components/TtButton.vue";
Vue.component("TtButton", TtButton);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Configuration VueAnalytics
import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: { id: "G-X6N9DNETMY" }
  },
  router
);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
