<template>
  <div class="area py-16">
    <div class="max-w-2xl">
      <h1 class="text-4xl">About</h1>
      <h2 class=" text-xl xl:text-xl pt-6 xl:pt-12 font-bold">
        What is the essence of the concept?
      </h2>
      <p class="pt-1 xl:pt-3">
        Wikipedia represents the largest database of knowledge of our time. The
        English Wikipedia alone has more than six million articles, over a
        million of which include coordinates. This gave rise to the idea of
        making the available knowledge tangible in a new way. On walks, long car
        rides or during a trip around the world, Triptalk now lets you explore
        the world with podcasts thanks to neural networks. The app is aimed at
        travelers, tourists and passengers who want to broaden their horizons.
      </p>
      <h2 class=" text-xl xl:text-xl pt-6 xl:pt-12 font-bold">
        How was the app created?
      </h2>
      <p class="pt-1 xl:pt-3">
        The content is based on the content of Wikipedia. The texts are
        summarized by neural networks, interviews are simulated by
        question-answer pairs. The text is synthesized, provided with background
        music and processed to a podcast. These podcasts can be selected from a
        map and played. Especially for users with limited vision or illiteracy,
        the audio-based presentation of the content helps to learn more about
        the environment. The development was done during one semester and could
        be tested regularly on fellow students. One month after the launch, it
        already counts over 1500 users worldwide. The resulting data helps to
        further develop and design the app.
      </p>
      <h2 class=" text-xl xl:text-xl pt-6 xl:pt-12 font-bold">
        What novel experience does the app offer?
      </h2>
      <p class="pt-1 xl:pt-3">
        Triptalk converts articles from Wikipedia into podcasts and locates them
        on a map. This way, the largest constantly growing and updating database
        is used as an audio travel guide. If the autoplay mode is activated,
        podcasts are played automatically as soon as a noted location is nearby.
        This way, long drives, walks or bike rides can be turned into a travel
        guide. Other travel guides, on the other hand, use their own content,
        which is not as varied and, in most cases, not set to music.
      </p>
      <h2 class=" text-xl xl:text-xl pt-6 xl:pt-12 font-bold">
        Where was the app developed?
      </h2>
      <p class="pt-1 xl:pt-3">
        The development was created during the course "Designing with Voice and
        Language" with Prof. Daniela Hensel at the HTW Berlin in the
        communication design program and beyond. A particular challenge was the
        rapid development of the concept and the MVP within three months,
        especially since I was working on the project alone. On March 12, the
        MVP was launched in the Apple App Store and the Google Play Store and
        after one month counts over 1500 users. Since then, the app has been
        continuously developed based on user feedback.
      </p>
      <h2 class=" text-xl xl:text-xl pt-6 xl:pt-12 font-bold">
        Which concrete approaches (ethical, social, ecological) are applied in
        the design process?
      </h2>
      <p class="pt-1 xl:pt-3">
        Like Wikipedia, Triptalk makes the world's knowledge available. However,
        the content on Triptalk can be experienced not only visually, but also
        auditorily. This means that people with limited vision and illiterate
        people can also consume the content. Furthermore, the app is also
        available to people who do not actually have access to Wikipedia. In
        China, Turkey, Syria and Saudi Arabia, Wikipedia is or was fully
        blocked, but Triptalk is fully available in these countries.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
