<template>
  <div>
    <TtHero></TtHero>
    <TtList></TtList>
  </div>
</template>

<script>
// @ is an alias to /src
import TtHero from "@/components/TtHero.vue";
import TtList from "@/components/TtList.vue";

export default {
  name: "Home",
  components: { TtHero, TtList }
};
</script>
