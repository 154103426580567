<template>
  <footer
    id="footer"
    class="absolute bottom-0 w-full bg-gray-100 text-gray-600"
  >
    <div
      class="area flex flex-col sm:flex-row space-y-4 sm:space-y-0 justify-between py-8"
    >
      <div class="flex space-x-4 items-center">
        <router-link to="/">
          <img
            class="object-contain w-16"
            alt="Triptalk logo"
            style="filter: saturate(0);"
            src="../assets/logo-yellow.png"
        /></router-link>
        <div>
          <h4 class="text-md leading-tight">
            Explore your <br />
            Sourroundings.
          </h4>
          <div class="flex space-x-2 mt-1">
            <a
              href="https://www.instagram.com/triptalk.travel.guide/"
              target="noopener"
            >
              <ion-icon
                class="cursor-pointer mt-1  text-2xl"
                name="logo-instagram"
              ></ion-icon>
            </a>
            <a
              href="https://www.facebook.com/Triptalk-102753165221550"
              target="noopener"
            >
              <ion-icon
                class="cursor-pointer mt-1  text-2xl"
                name="logo-facebook"
              ></ion-icon>
            </a>
            <a
              href="https://www.youtube.com/channel/UCbv1QSHb9oUAzUPY_qESfyQ"
              target="noopener"
            >
              <ion-icon
                class="cursor-pointer mt-1  text-2xl"
                name="logo-youtube"
              ></ion-icon>
            </a>
          </div>
        </div>
      </div>
      <div>
        <h4 class="mb-1 font-bold">SITES</h4>
        <router-link to="/about"> <p>About</p></router-link>
        <router-link to="/contact"> <p>Contact</p></router-link>
        <router-link to="/app"> <p>Get the App!</p></router-link>
        <router-link to="/dsvgo"> <p>Privacy Statement</p></router-link>
      </div>
    </div>
    <div class="border-t-2 border-gray-300 text-center area py-8 text-">
      <p>&copy; 2021 triptalk.space</p>
    </div>
  </footer>
</template>

<script>
export default {
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    $route() {
      this.handleResize();
    }
  },
  mounted() {
    this.handleResize();
  },
  methods: {
    handleResize() {
      const self = this;
      this.$nextTick().then(function() {
        const el = document.getElementById("footer");
        self.$emit("footer-height", el.offsetHeight);
      });
    }
  }
};
</script>

<style></style>
