<template>
  <div
    id="hero"
    class="bg-hero-sm sm:bg-hero-md md:bg-hero-lg lg:bg-hero-xl xl:bg-hero-2xl bg-cover bg-no-repeat bg-right bg-clip-border static"
    style="background-position: 56% 50%"
  >
    <div class="area flex items-start justify-between space-x-16 lg:h-70vh">
      <div class="text-white text-shadow-lg pt-16 lg:pt-32">
        <h1 class="text-4xl md:text-6xl font-bold leading-none">
          Your personal <br />
          Audio Guide.
        </h1>
        <p class="text-xl md:text-2xl mt-2">
          With over <br class=" block lg:hidden" />
          <u class="underline">one million podcasts</u>.
        </p>
        <TtButton link="/app" class=" mr-auto mt-6" icon="globe-outline"
          >Start Exploring!</TtButton
        >
      </div>
    </div>
    <div class="area flex justify-end">
      <button
        class="cursor-pointer inline my-8 focus:outline-none"
        @click="scrollToList"
      >
        <ion-icon
          class="text-white text-3xl md:text-6xl text-shadow-lg self-end"
          name="arrow-down-outline"
        ></ion-icon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToList() {
      const el = document.getElementById("list");
      el.scrollIntoView({
        behavior: "smooth"
      });
    }
  }
};
</script>

<style></style>
