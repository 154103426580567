<template>
  <div class="fixed bottom-0 w-full">
    <div class="area">
      <div
        class=" bg-white my-4 p-4 rounded-lg border-tt-yellow border-2 flex justify-between items-center"
      >
        <p>
          We use cookies to improve your experience on our site. By using our
          site you consent cookies.
          <router-link class=" underline" to="/dsvgo"> Learn more</router-link>
        </p>
        <TtButton
          @clicked="$emit('clicked')"
          color="transparent"
          icon="checkmark-outline"
          >Okay</TtButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import TtButton from "./TtButton.vue";
export default {
  components: { TtButton }
};
</script>

<style></style>
