<template>
  <button
    @click="push"
    class="py-2 px-6 inline-flex items-center rounded-lg cursor-pointer text-black focus:outline-none"
    v-bind:class="color"
    v-wave="{
      color: '#FFF2CC',
      duration: 0.3,
      initialOpacity: 0.5,
      easing: 'ease-in'
    }"
  >
    <ion-icon size="small" :name="icon"></ion-icon>
    <p class="ml-4 font-bold"><slot></slot></p>
  </button>
</template>

<script>
import router from "@/router";
export default {
  router,
  props: {
    color: {
      type: String,
      default: "bg-tt-yellow"
    },
    link: {
      type: String,
      default: ""
    },
    icon: String
  },
  methods: {
    push() {
      this.$emit("clicked");
      if (this.link !== "") {
        router.push(this.link);
      }
    }
  }
};
</script>

<style></style>
