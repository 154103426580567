<template>
  <div>
    <div id="list" class="area py-16 space-y-16">
      <div class="max-w-4xl space-y-4">
        <h1 class="text-4xl">
          The Triptalk Travel Guide is a worldwide usable audio guide.
        </h1>
        <p>
          Based on Wikipedia texts, multiple neural networks are used to
          generatively create podcasts with summaries, interviews, etc. This
          way, any walk, car ride or world trip can be turned into an exciting
          journey through history.
        </p>
        <p>
          <b>The app is available for iOS and Android free of charge!</b>
        </p>
      </div>
      <ul class="flex flex-wrap gap-4 text-md lg:text-xl mt-8 ">
        <li class=" w-96 p-4 shadow-lg rounded-lg">
          <h2 class="flex items-center text-xl">
            <ion-icon
              class="mr-4 md:mr-6 text-tt-yellow"
              name="arrow-forward-circle-outline"
            ></ion-icon>
            Over one million <br class="hidden md:block lg:hidden" />
            curated podcasts.
          </h2>
          <p class="mt-4 text-base">
            Triptalk offers a wide range of audio content worldwide. Whether
            medieval walls, historically important places or modern
            architecture, you can now find it all on your phone.
          </p>
        </li>
        <li class=" w-96 p-4 shadow-lg rounded-lg">
          <h2 class="flex items-center text-xl">
            <ion-icon
              class="mr-4 md:mr-6 text-tt-yellow"
              name="arrow-forward-circle-outline"
            ></ion-icon>
            GPS-controlled <br class="hidden md:block lg:hidden" />
            audio playback.
          </h2>
          <p class="mt-4 text-base">
            Thanks to GPS-controlled audio playback, you can concentrate on your
            surroundings and don't have to look at the display. Simply activate
            autoplay, plug in your headphones and explore your surroundings.
          </p>
        </li>
        <li class=" w-96 p-4 shadow-lg rounded-lg">
          <h2 class="flex items-center text-xl">
            <ion-icon
              class="mr-4 md:mr-6 text-tt-yellow"
              name="arrow-forward-circle-outline"
            ></ion-icon>
            The whole world <br class="hidden md:block lg:hidden" />
            on a map.
          </h2>
          <p class="mt-4 text-base">
            Find yourself and all the locations you're interested in on a map.
            Explore your surroundings during your trip or explore your
            destinations in advance.
          </p>
        </li>
        <li class=" w-96 p-4 shadow-lg rounded-lg">
          <h2 class="flex items-center text-xl">
            <ion-icon
              class="mr-4 md:mr-6 text-tt-yellow"
              name="arrow-forward-circle-outline"
            ></ion-icon
            >Keep the overview
          </h2>
          <p class="mt-4 text-base max-w-md">
            Where have you been and what will you enjoy next? With clearly
            arranged playlists you can see at a glance what you have already
            explored on your trip and which exciting sights are waiting for you
            next.
          </p>
        </li>
      </ul>
    </div>
    <img
      id="mockup"
      class="object-fill"
      alt="Mockup of the Triptalk App"
      src="../assets/mockup.png"
    />
    <div class="area py-16 flex flex-wrap justify-between items-center">
      <div class="">
        <h3 class="max-w-4xl text-4xl ">Hungry to travel?</h3>
        <p class="pt-3 max-w-xl">
          Then you have found your guide! Explore your journeys and let us guide
          you on your itinerary. With insider tips, location descriptions, maps
          and podcasts, your trip will be one to remember.
        </p>
      </div>
      <TtButton class="block my-4" link="/app" icon="download"
        >Get the App!</TtButton
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
