<template>
  <div id="app">
    <TtNavbar></TtNavbar>
    <div class="min-h-screen relative" style="min-height: calc(100vh - 56px)">
      <div
        v-bind:style="
          'padding-bottom:' + footerHeight + 'px; padding-top: 56px'
        "
      >
        <router-view />
      </div>
      <TtFooter @footer-height="setFooterHeight"></TtFooter>
      <TtCookie v-if="!cookie" @clicked="cookieAccepted"></TtCookie>
    </div>
  </div>
</template>

<script>
import TtNavbar from "@/components/TtNavbar.vue";
import TtFooter from "@/components/TtFooter.vue";
import TtCookie from "@/components/TtCookie.vue";

export default {
  data() {
    return { footerHeight: 0, cookie: false };
  },
  components: {
    TtCookie,
    TtFooter,
    TtNavbar
  },
  mounted() {
    if (this.$cookies.isKey("COOKIE")) {
      this.cookie = true;
    }
  },
  methods: {
    setFooterHeight(height) {
      this.footerHeight = height;
    },
    cookieAccepted() {
      this.cookie = true;
      this.$cookies.set("COOKIE", "true", 60 * 60 * 24 * 30);
    }
  }
};
</script>
